<script setup>
import { useCartStore } from '@/stores/Cart'

const cart = useCartStore()
const { formatUSD } = useFormatters()
const staticURLImg = useRuntimeConfig().public.staticURLImg

// Props for product
const props = defineProps({
    product: {
        type: Object,
        default: null,
    },
})

const currentPrice = computed(() => {
    if (props.product && props.product.productPrices.totalCount > 0) {
        return props.product.productPrices.edges[0].node.price
    } else {
        return props.product.price
    }
})
</script>

<template>
    <v-card variant="flat" class="d-flex flex-column st-card st-box-shadow">
        <nuxt-link :to="'/shop/' + product.handle" :aria-label="`Explore ${product.name}`">
            <v-img id="cardImg" :src="staticURLImg + product.productImages.edges[0]?.node.source" :alt="product.name">
                <v-container class="h-50 pl-2 pr-1 pt-0 d-flex justify-start align-start text-grey-lighten-3">
                    <span
                        v-if="currentPrice > 0 && currentPrice != product.price"
                        class="px-3 pb-4 bg-secondary text-button st-sales-badge"
                    >
                        Sale
                    </span>
                    <v-spacer></v-spacer>
                    <span class="pt-1">
                        <v-chip
                            density="compact"
                            size="small"
                            class="bg-grey-lighten-5 text-caption font-weight-medium"
                        >
                            {{ product.category.name }}
                        </v-chip>
                    </span>
                </v-container>
            </v-img>
        </nuxt-link>
        <v-row no-gutters>
            <v-col>
                <v-spacer></v-spacer>
                <app-button
                    icon
                    :btn-icon="`mdi-cart-plus`"
                    :show-tooltip="true"
                    :tooltip-text="`Add to Cart`"
                    @click.stop="cart.addProduct(product)"
                    class="mt-n3 mr-2 d-flex justify-end"
                    aria-label="Add to Cart"
                />
            </v-col>
            <!-- <v-col>
                <span class="mt-n4 mr-2 d-flex justify-end">
                    <v-chip v-if="currentPrice > 0" color="sthighlight" variant="flat" class="st-box-shadow">
                        <span class="text-button">{{ formatUSD(currentPrice) }}</span>
                    </v-chip>
                    <v-chip v-else color="sthighlight" variant="flat" class="st-box-shadow">
                        <span class="text-button">FREE</span>
                    </v-chip>
                </span>
            </v-col> -->
        </v-row>
        <v-card-text class="pb-2">
            <app-link
                :to="`/seller/${product.seller.handle}`"
                :lnk-text="product.seller.displayName"
                :aria-label="`Explore ${product.seller.displayName}`"
                style="font-size: 0.9rem !important; font-weight: 500 !important; line-height: 1.28rem !important"
            />
        </v-card-text>
        <v-card-title class="pt-0 pb-1 text-wrap">
            <div style="font-size: 1.1rem !important; font-weight: 600 !important">{{ product.name }}</div>
        </v-card-title>
        <v-card-subtitle class="pt-0 pb-2">{{ product.tagline }}</v-card-subtitle>
        <v-card-text class="text-subtitle-1 font-weight-medium">
            <span :class="currentPrice > 0 && currentPrice != product.price ? 'text-decoration-line-through' : null">
                {{ formatUSD(product.price) }}
            </span>
            <span>&nbsp;</span>
            <span v-if="currentPrice > 0 && currentPrice != product.price" class="px-2 bg-secondary rounded-pill">
                {{ formatUSD(currentPrice) }}
            </span>
        </v-card-text>
        <!-- <v-card-actions class="justify-space-between"> -->
        <!-- <app-button
                :to="`/shop/${product.handle}`"
                :btn-text="`Explore Now`"
                :variant="`text`"
                :append-icon="`mdi-chevron-right`"
                :aria-label="`Explore ${product.name}`"
            /> -->
        <!-- <app-button
                :prepend-icon="`mdi-cart-plus`"
                :btn-text="`Add to Cart`"
                :variant="`text`"
                :size="`small`"
                @click.stop="cart.addProduct(product)"
                aria-label="Add to Cart"
                :btn-color="`primary`"
            /> -->
        <!-- </v-card-actions> -->
    </v-card>
</template>

<style scoped></style>
